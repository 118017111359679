
  
  .nav-logo{
    justify-content: space-between;
  }
  
  .nav-logo img{
    width: 5em;
  }
  
  .login-form{
    background-color: rgb(195 195 191 / 30%);
  }
  
  form .btn{
    background: white;
    box-shadow: 5px 4px 6px #ccc;
    color: #525FE1;
    font-size: 18px;
    width: 12em;
    border: 0 none;
  }
  
  form .btn:hover{
    background: #F86F03;
    color: white;
  }
  

  .frgt_pass{
    color: #525FE1;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
   
  }

  .frgt_pass_login{
    float: right;
    margin: -30px 5px 0px 0px;
  }
  
  .frgt_pass:hover{
    color: #F86F03;
  }