#gjs {
    border: 3px solid #444;
  }
  
  /* Reset some default styling */
  .gjs-cv-canvas {
    top: 0;
    width: 100%;
    height: 100%;
  }
  
  .gjs-frames div.gjs-frame-wrapper {
    top: 75px !important;
    height: calc(100% - 100px);
  }
  .gjs-frame {
    top: 0px;
  }
  .gjs-cv-canvas.gjs-no-touch-actions.gjs-cv-canvas-bg {
    width: 80%;
  }
  .gjs-pn-views-container {
    max-height: 100%;
    /* position: fixed; */
    top: 0;
    right: 0;
  }