body{
    color: #8e9194;
    background-color: #f4f6f9;
}
.avatar-xl img {
    width: 162px;
    height: 162px
}
.rounded-circle {
    border-radius: 50% !important;
}

img {
    vertical-align: middle;
    border-style: none;
}
.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4d5154;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #eef0f3;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}